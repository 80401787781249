<template>
  <div style="min-height:100vh" id="inform">
    <van-nav-bar title="重要告知" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <div class="x-tabl">重要告知：</div>
      <p>投保人可登录保险人官方网站，或通过保险人全国统一服务电话、营业网点核实保险合同信息及查询保险理赔信息。请务必详细阅读保险条款，特别注意特别约定、重要告知、责任免除等内容。若对查询结果有异议，可致电保险人全国统一服务电话咨询。</p>

      <br>
      <div class="x-tabl">特别约定：</div>
      <p>1、并发症列表<br>
      <ul>
        <li v-for="(item,index) in explain" :key="index">{{item.content}}的并发症保险金额为：{{item.amount}}</li>
      </ul>
      2、被保险人投保后首次接受的非急诊{{planName}}为本合同约定的非急诊{{planName}}；<br>
      3、本合同约定的非急诊手术以被保险人实际接受手术时间晚于投保时间六小时以上为准； <br>
      4、本合同所指的手术意外、介入诊疗意外和麻醉意外，均不包括医疗事故。同一保险事故导致被保险人身故或伤残，意外伤害保险金和医疗事 故保险金只给付其中一项；<br>
      5、本公司承保的并发症及其分项保险金额均以保险单（或保险凭证）上载明的为准。<br>
      6、投保声明：<br>
      贵公司已对保险合同的条款内容履行了说明义务，并对责任免除条款履行了明确说明义务。本投保人已仔细阅知，理解投保提示及保险条 款尤其是责任免除、解除合同等规定，并同意遵守。所填投保单各项及告知事项均属事实并确无欺瞒。上述一切陈述及本声明将成为贵公司承 保的依据，并作为保险合同的一部分。如有不实告知，贵公司有权在法定期限内解除合同，并依法决定是否对合同解除前发生的保险事故承担 保险责任。
      <br>
      7、未成年人投保特别约定：<br>
      根据中国保险监督管理委员会《关于父母为其未成年子女投保以死亡为给付保险金条件人身保险有关问题的通知》（保监发[2015]90号） 文件的规定，自2016年1月1日起，对于父母为其未成年子女投保的人身保险，在被保险人成年之前，各保险合同约定的被保险人死亡给付的保险 金额总和、被保险人死亡时各保险公司实际给付的保险金总和按以下限额执行： <br>
      （1）对于被保险人未满10周岁的，不得超过人民币20万元(乘坐飞机意外伤害身故除外)；<br>
      （2）对于被保险人已满10周岁但未满18周岁的，不得超过人民币50万元
      </p>
    </div>
    <div class="x-button-dom">
        <van-button size="small" :class="Is_Activated?'x-button':'x-button-no'" @click="goToTouBao()">完成阅读，前往投保</van-button>
      </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {getSession} from "../util/sessionStorage";
export default {
  components: {},
  computed: {
    ...mapState({
      explain: "explain",
      planName: "planName",
    }),
  },
  data() {
    return {
      Is_Activated:false,//是否激活
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goToTouBao() {
      if(!this.Is_Activated){
        return
      }
      let partner = getSession("partner");
      if("anlan"===partner){
        this.$router.push({ path: "/touBaoAnLan/" + this.$route.params.id });
      } else {
        this.$router.push({ path: "/touBao/" + this.$route.params.id });
      }

    },
  },
  mounted() {
    let _this = this;
    document.title = "重要通知";
    //变量windowHeight是可视区的高度
    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    var offHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
    if(windowHeight == offHeight){
        _this.Is_Activated = true;
      }
    window.onscroll = function(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
        //滚动条到底部的条件
        if(scrollTop+windowHeight >= scrollHeight - 10){
        //到了这个就可以进行业务逻辑加载后台数据了
          _this.Is_Activated = true;
        }
      }
  },
};
</script>
<style lang="less" scoped>
.content {
  background-color: white;
  padding: 16px;
  padding-bottom: 90px;
}
p {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
}
.x-tabl {
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
}
.x-button-dom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  background-color: white;
  padding-bottom: 25px;
  padding-top: 10px;

  .x-button {
    width: 252px;
    height: 44px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin: 0px auto;
    border: none;
  }

  .x-button-no{
    width: 252px;
    height: 44px;
    background-color: #9C9C9C;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin: 0px auto;
    border: none;
  }
}

</style>
